<template>
  <app-list-view
    server-side
    hideCreate
    app="audit"
    model="auditplanheader"
    api-url="audit/audit-plan-header/"
    :title="$t('menu.auditPlan')"
    :editTo="{ name: 'auditPlanEdit' }"
    v-model="selected"
    :headers="headers"
    @edit="onEdit"
    @delete="onDelete"
  >
    <template v-slot:prefix-actions>
      <v-btn color="primary" @click="openDialogAuditPlan">
        {{ $t('btn.create') }}
      </v-btn>
    </template>
    <template v-slot:top>
      <v-dialog v-model="dialogAuditPlan" max-width="1500px">
        <v-card flat>
          <v-card-text class="pt-4">
            <v-toolbar dense color="primary white--text">
              <v-toolbar-title>
                {{ $t('menu.suggestionAuditPlan') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-row class="mt-4">
              <v-col cols="12" sm="6" md="4">
                <app-input
                  name="company"
                  type="select-server"
                  :label="$t('fields.selectCompany')"
                  :binds="{
                    apiUrl:
                      'contact/contact/?contact_type=company&state=approved&active=true'
                  }"
                  v-model="formData.contact_id"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <app-input
                  name="suggestType"
                  type="select"
                  :label="$t('fields.suggestType')"
                  :binds="{
                    apiUrl: 'doc-template/document/?active=true'
                  }"
                  :items="[
                    {
                      label: 'Suggest',
                      value: 'suggest'
                    },
                    {
                      label: 'Display All',
                      value: 'display_all'
                    },
                    {
                      label: 'Duplicate Audit Plan',
                      value: 'duplicate'
                    }
                  ]"
                  v-model="formData.suggest_type"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="
                formData.suggest_type !== null &&
                formData.suggest_type !== undefined
              "
            >
              <v-col cols="12" sm="12">
                <app-table
                  server-side
                  app="audit"
                  model="auditplanheader"
                  :headers="suggestionHeader"
                  :serverItems="suggestionItemItems"
                  :clientItems="suggestionItemItems"
                  :loading="loading"
                  :server-items-length="suggestionItemServerItemsLength"
                  v-model="selectedSuggest"
                  @server="getSuggestion"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeAuditPlan">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="createAuditPlan">
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </app-list-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppListView from '@components/AppListView'
import AppTable from '@components/AppTable.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'auditPlanList',
  components: {
    AppListView,
    AppInput,
    AppTable
  },
  props: {
    hideEdit: {
      type: Boolean,
      default: false
    },
    hideDelete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      AuditPlanItems: [],
      AuditPlanSelected: [],
      AuditPlanClientItems: [],
      AuditPlanItemsLength: 0,
      dialogAuditPlan: false,
      apiMethod: 'post',
      loading: false,
      formData: {
        state: 'draft'
      },
      selected: [],
      selectedSuggest: [],
      suggestionItemItems: [],
      suggestionItemServerItemsLength: 0
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'auditPlan',
      text: 'menu.auditPlan',
      to: { name: 'auditPlan' }
    })
  },
  watch: {
    'formData.suggest_type': function () {
      this.getSuggestion()
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    headers() {
      return [
        {
          text: this.$t('fields.companyName'),
          value: 'contact_id.name'
        },
        { text: this.$t('fields.sectionName'), value: 'name' },
        { text: this.$t('fields.auditYear'), value: 'audit_year' },
        { text: this.$t('fields.period'), value: 'audit_period' },
        {
          text: this.$t('fields.companyType'),
          value: 'contact_id.contact_type'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    },
    enableDelete() {
      return (
        !this.hideDelete &&
        this.permissions.includes(`${this.app}.delete_${this.model}`)
      )
    },
    suggestionHeader() {
      return this.formData.suggest_type === 'duplicate'
        ? [
            {
              text: this.$t('fields.auditPlan'),
              value: 'audit_plan_name'
            },

            {
              text: this.$t('fields.contactName'),
              value: 'company_name'
            },
            {
              text: this.$t('fields.fiscalYear'),
              value: 'fiscal_year'
            },
            {
              text: this.$t('fields.auditPeriod'),
              value: 'audit_period'
            }
          ]
        : [
            {
              text: this.$t('fields.auditProgram'),
              value: 'audit_program_name'
            },
            {
              text: this.$t('fields.activityProfile'),
              value: 'audit_activity_profile_name'
            },
            {
              text: this.$t('fields.riskProfile'),
              value: 'audit_risk_profile_name'
            },
            {
              text: this.$t('fields.industry'),
              value: 'audit_industry_name'
            },
            {
              text: this.$t('fields.category'),
              value: 'audit_category_name'
            },
            {
              text: this.$t('fields.businessType'),
              value: 'audit_business_type_name'
            },
            {
              text: this.$t('fields.date'),
              value: 'date',
              hideFilter: true,
              isDate: true
            }
          ]
    }
  },
  methods: {
    openDialogAuditPlan() {
      this.dialogAuditPlan = true
    },
    closeAuditPlan() {
      this.formData = {}
      this.dialogAuditPlan = false
    },
    getSuggestion(query = { page: 1, itemsPerPage: 10 }) {
      const offset = (query.page - 1) * query.itemsPerPage
      this.$api({
        method: 'get',
        url: `/audit/audit-plan-suggest/?contact_id=${this.formData.contact_id.id}&suggest_type=${this.formData.suggest_type}`,
        hideSuccessAlert: true,
        params: {
          limit: query.itemsPerPage,
          offset: offset,
          ...query?.multiSearch
        }
      }).then(({ data }) => {
        this.suggestionItemServerItemsLength = data.count
        this.suggestionItemItems = data.results
      })
    },
    async createAuditPlan() {
      this.loading = true
      const data = {}
      if (
        this.formData.contact_id !== null &&
        this.formData.contact_id !== undefined
      ) {
        data.contact_id = this.formData.contact_id.id
      }
      if (
        this.formData.suggest_type !== null &&
        this.formData.suggest_type !== undefined
      ) {
        data.suggest_type = this.formData.suggest_type
      }
      data.ids = this.selectedSuggest.map((value) =>
        this.formData.suggest_type === 'duplicate'
          ? value.audit_plan_id
          : value.audit_program_id
      )

      data.audit_plan_id = null

      if (this.selectedSuggest.length !== 0) {
        await this.$api({
          method: this.apiMethod,
          url: `/audit/audit-plan-suggest/`,
          data
        }).then(({ data }) => {
          this.$router.push({
            name: 'auditPlanEdit',
            params: { id: data.audit_plan_header_id }
          })
        })
      } else {
        this.$router.push({
          name: 'auditPlanCreate'
        })
      }

      this.loading = false
    },
    onEdit(item) {
      if (this.editTo) {
        this.$router.push({
          ...this.editTo,
          params: { id: item.id }
        })
      }
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-plan-header/`,
          data: {
            pks: items.map((value) => value.id)
          },
          hideSuccessAlert: true
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    prepareData(data) {
      data.contact_id = data.contact_id.id
      data.audit_plan_id = data.audit_plan_id.id
      return data
    }
  }
}
</script>
